.App {
  text-align: center;
}

q {
  font-size: larger;
  font-style: italic;
}

.image-font {
  color: rgb(255, 255, 255);
}

img {
  padding: 10px;
}

p {
  display: block;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.card {
  color: rgb(255,255,255) !important;
  background-color: rgba(50, 50, 50, 0.8) !important;
  margin: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.card-title {
  padding: 10px;
}

a:not(.nav-link):not(.navbar-brand) {
  color: black !important;
}


div.pretty-header-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

img.pretty-header-image {
  min-width: 100%;
  min-height: 100%;
  padding: 0;
}
.pretty-header {
  width: 100%;
  height: 100%;
}

/*
text-align: center;
background-image: url(./images/home.jpg);
background-repeat: no-repeat;
background-size: cover;
background-attachment: fixed;
background-position: center;
*/

h1, h2 {
  text-align: center;
}